import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Article, SanityKeyed, SanityBlock } from '../../../schema'
import { WithData } from '@utils'
import { BlockRenderer, Grid, BackUp, BackHome } from '../'
import { H1, CyanText } from '../BlockRenderer'



interface ArticleProps {
  data: ArticleData
}

interface ArticleData {
  sanityArticle: ArticleWithRaws
}

interface ArticleWithRaws extends Article {
  _rawCopy: Array<SanityKeyed<SanityBlock>>
}


const ArticleTemplate = ({
  data: {
    sanityArticle
  }
}: ArticleProps): React.ReactElement => {

  const meta = [
    { property: "og:title", content: sanityArticle.title },
    { name: "twitter:title", content: sanityArticle.title },
    { name: "description", content: sanityArticle.description },
    { name: "twitter:description", content: sanityArticle.description },
    { property: "og:description", content: sanityArticle.description },    
    { property: "og:image", content:  sanityArticle.shareImage?.asset.url },
    { name: "twitter:image", content: sanityArticle.shareImage?.asset.url },
  ]

  return (
    <>
      <Helmet {...{meta}} />
      <Grid>
        <Wrapper>
          <BackHome />
  
          <Headline>
            <H1>{sanityArticle.title} <CyanText>{sanityArticle.sourceName}</CyanText></H1>
          </Headline>
          <BlockRenderer>{sanityArticle._rawCopy}</BlockRenderer>
  
          <BackUp />
  
        </Wrapper>
      </Grid>
    </>
  )
}


const Wrapper = styled.article`
  @media only screen and (min-width: 744px) {
    grid-column-end: span 2;
  }
`

const Headline = styled.div`
  margin-bottom: 2em;
`





const mapDataToProps = ({ sanityArticle }: ArticleData) => ({ ...sanityArticle })


export const query = graphql`
  query article($slug: String!) {
    sanityArticle(slug: {current: {eq: $slug}}) {
      title
      slug {
        current
      }
      sourceName
      sourceLink
      _rawCopy
    }
  }
`

export default WithData(mapDataToProps, ArticleTemplate)